/* You can add global styles to this file, and also import other style files */
/*================================================
Default CSS
=================================================*/
:root {
  --fontFamily: "Source Sans Pro", sans-serif;
  --mainColor: #FFC107;
  --optionalColor: #000104;
  --whiteColor: #ffffff;
  --blackColor: #000000;
  --fontSize: 16px;
  --transition: .5s;
}

body {
  margin: 0;
  padding: 0;
  color: var(--blackColor);
  font-size: var(--fontSize);
  font-family: var(--fontFamily);
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  font-weight: 700;
  color: var(--blackColor);
}

a {
  color: var(--blackColor);
  transition: var(--transition);
  text-decoration: none;
  outline: 0 !important;
}
a:hover {
  color: var(--mainColor);
  text-decoration: none;
}

:focus {
  outline: 0 !important;
}

.d-table {
  width: 100%;
  height: 100%;
}
.d-table-cell {
  vertical-align: middle;
}

img {
  max-width: 100%;
  height: auto;
}

p {
  color: var(--optionalColor);
  font-size: var(--fontSize);
  margin-bottom: 15px;
  line-height: 1.8;
}
p:last-child {
  margin-bottom: 0;
}

.ptb-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}

.pt-100 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.pb-100 {
  padding-bottom: 100px;
}

.ptb-70 {
  padding-top: 70px;
  padding-bottom: 70px;
}

.pt-70 {
  padding-top: 70px;
}

.pb-70 {
  padding-bottom: 70px;
}

.container {
  max-width: 1230px;
}

.bg-f5f5f5 {
  background-color: #f5f5f5;
}

.bg-gradient {
  background-color: linear-gradient(to right, #805B10, #edc531);
}

.bg-e8fdff {
  background-color: #e8fdff;
}

.bg-fafafa {
  background-color: #fafafa;
}

/*section-title*/
.section-title {
  text-align: center;
  max-width: 720px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 60px;
  margin-top: 20px;
}
.section-title .sub-title {
  display: block;
  font-weight: 600;
  margin-bottom: 12px;
}
.section-title h2 {
  font-size: 36px;
  margin-bottom: 12px;
}
.section-title p {
  max-width: 650px;
  margin-left: auto;
  margin-right: auto;
}

/*default-btn*/
.default-btn {
  border: none;
  position: relative;
  display: inline-block;
  color: var(--whiteColor);
  box-shadow: unset !important;
  transition: var(--transition);
  background: linear-gradient(to right, #805B10, #edc531);
  padding: 13px 30px 12px 55px;
  font-weight: 600;
  font-size: var(--fontSize);
}
.default-btn i {
  top: 50%;
  left: 28px;
  font-size: 20px;
  position: absolute;
  margin-top: -1px;
  transform: translateY(-50%);
}
.default-btn:hover {
  color: var(--whiteColor);
  background-color: var(--blackColor);
}

/*form-control*/
.form-control {
  border-radius: 0;
  background-color: #e1e1e1 !important;
  box-shadow: unset !important;
  transition: var(--transition);
  border: none !important;
  height: 50px;
  padding-left: 15px;
  color: var(--blackColor);
  font-size: var(--fontSize);
  font-weight: 500;
}
.form-control::placeholder {
  color: #999999;
  transition: var(--transition);
}
.form-control:focus::placeholder {
  color: transparent;
}

textarea.form-control {
  padding-top: 15px;
  height: auto;
}

/*owl-carousel-css*/
.home-slides .owl-theme .owl-nav {
  left: 80px;
  bottom: 0;
  margin-top: 0;
  position: absolute;
}
.home-slides .owl-theme .owl-nav [class*=owl-] {
  font-size: 25px;
  margin: 0 5px;
  padding: 0;
  width: 45px;
  height: 45px;
  background: #f5f5f5;
  position: relative;
  border-radius: 50%;
  color: var(--optionalColor);
  text-align: center;
  transition: var(--transition);
}
.home-slides .owl-theme .owl-nav [class*=owl-] i {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.home-slides .owl-theme .owl-nav [class*=owl-].owl-prev {
  margin-left: 0;
}
.home-slides .owl-theme .owl-nav [class*=owl-].owl-next {
  margin-right: 0;
}
.home-slides .owl-theme .owl-nav [class*=owl-]:hover {
  color: var(--whiteColor);
  background-color: var(--mainColor);
}

.home-slides-two .owl-theme .owl-nav {
  margin-top: 0;
  margin-bottom: 100px;
}
.home-slides-two .owl-theme .owl-nav [class*=owl-] {
  font-size: 25px;
  margin: 0 5px;
  padding: 0;
  width: 45px;
  height: 45px;
  background: #f5f5f5;
  position: relative;
  border-radius: 50%;
  color: var(--optionalColor);
  text-align: center;
  transition: var(--transition);
}
.home-slides-two .owl-theme .owl-nav [class*=owl-] i {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.home-slides-two .owl-theme .owl-nav [class*=owl-].owl-prev {
  margin-left: 0;
}
.home-slides-two .owl-theme .owl-nav [class*=owl-].owl-next {
  margin-right: 0;
}
.home-slides-two .owl-theme .owl-nav [class*=owl-]:hover {
  color: var(--whiteColor);
  background-color: var(--mainColor);
}

.testimonials-slides .owl-theme .owl-nav [class*=owl-] {
  margin: 0;
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  background-color: var(--whiteColor);
  color: var(--blackColor);
  border-radius: 50%;
  font-size: 25px;
  padding: 0;
  text-align: center;
  transition: var(--transition);
}
.testimonials-slides .owl-theme .owl-nav [class*=owl-] i {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.testimonials-slides .owl-theme .owl-nav [class*=owl-].owl-next {
  left: auto;
  right: 15px;
}
.testimonials-slides .owl-theme .owl-nav [class*=owl-]:hover {
  background-color: var(--blackColor);
  color: var(--whiteColor);
}

.products-slides .owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 10px;
}
.products-slides .owl-theme .owl-dots .owl-dot span {
  width: 17px;
  height: 17px;
  border-radius: 50%;
  background-color: transparent;
  border: 1px solid #666666;
  transition: var(--transition);
  margin: 0 4px;
  position: relative;
}
.products-slides .owl-theme .owl-dots .owl-dot span::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #666666;
  border-radius: 50%;
  margin: 4px;
  transition: var(--transition);
}
.products-slides .owl-theme .owl-dots .owl-dot:hover span, .products-slides .owl-theme .owl-dots .owl-dot.active span {
  border-color: var(--mainColor);
}
.products-slides .owl-theme .owl-dots .owl-dot:hover span::before, .products-slides .owl-theme .owl-dots .owl-dot.active span::before {
  background-color: var(--mainColor);
}

.products-details-image-slides .owl-theme {
  margin-bottom: 35px;
}
.products-details-image-slides .owl-theme .owl-nav {
  margin-top: 0;
  opacity: 0;
  visibility: hidden;
  transition: var(--transition);
}
.products-details-image-slides .owl-theme .owl-nav [class*=owl-] {
  margin: 0;
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  background-color: var(--whiteColor);
  color: var(--blackColor);
  border-radius: 50%;
  font-size: 25px;
  padding: 0;
  text-align: center;
  transition: var(--transition);
}
.products-details-image-slides .owl-theme .owl-nav [class*=owl-] i {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.products-details-image-slides .owl-theme .owl-nav [class*=owl-].owl-next {
  left: auto;
  right: 20px;
}
.products-details-image-slides .owl-theme .owl-nav [class*=owl-]:hover {
  background-color: var(--blackColor);
  color: var(--whiteColor);
}
.products-details-image-slides .owl-theme:hover .owl-nav {
  opacity: 1;
  visibility: visible;
}

/*extra-css*/
.page-title-area {
  text-align: center;
  margin-top: 0px;
  background-color: #f9f9f9;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 70px;
  padding-bottom: 70px;
  padding-left: 15px;
  padding-right: 15px;
}
.page-title-area h1 {
  font-size: 36px;
  margin-bottom: 15px;
}
.page-title-area ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.page-title-area ul li {
  display: inline-block;
  color: var(--blackColor);
  font-weight: 500;
  position: relative;
  margin-left: 11px;
  margin-right: 11px;
}
.page-title-area ul li a {
  display: block;
  color: var(--optionalColor);
}
.page-title-area ul li::before {
  content: "\e9b2";
  position: absolute;
  left: -20px;
  top: 1.3px;
  font-family: "boxicons";
  color: var(--optionalColor);
}
.page-title-area ul li:first-child::before {
  display: none;
}

.pagination-area {
  margin-top: 35px;
}
.pagination-area .ngx-pagination {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.pagination-area .ngx-pagination li {
  color: var(--blackColor);
  margin-left: 5px;
  margin-right: 5px;
  font-weight: 600;
  font-size: var(--fontSize);
}
.pagination-area .ngx-pagination li.disabled {
  color: var(--blackColor);
  background-color: transparent;
  border: 2px solid #f5f3f3;
  padding: 6px 15px;
}
.pagination-area .ngx-pagination li a {
  padding: 6px 15px;
  color: var(--blackColor);
  transition: var(--transition);
  border: 2px solid #f5f3f3;
  background-color: transparent;
}
.pagination-area .ngx-pagination li a:hover, .pagination-area .ngx-pagination li a.current {
  background-color: transparent;
  color: var(--blackColor);
  border-color: var(--mainColor);
}
.pagination-area .ngx-pagination li.current {
  background-color: transparent;
  color: var(--blackColor);
  border: 2px solid var(--mainColor);
  padding: 6px 15px;
}
.pagination-area .ngx-pagination li.pagination-previous::before {
  position: relative;
  top: -1px;
}
.pagination-area .ngx-pagination li.pagination-previous a::before {
  position: relative;
  top: -1px;
}
.pagination-area .ngx-pagination li.pagination-next::after {
  position: relative;
  top: -1px;
}
.pagination-area .ngx-pagination li.pagination-next a::after {
  position: relative;
  top: -1px;
}

.widget-area {
  padding-left: 15px;
}
.widget-area .widget {
  margin-bottom: 40px;
}
.widget-area .widget:last-child {
  margin-bottom: 0;
}
.widget-area .widget .widget-title {
  font-size: 20px;
  margin-bottom: 25px;
  text-transform: uppercase;
}
.widget-area .widget_search form {
  position: relative;
}
.widget-area .widget_search form label {
  display: block;
  margin-bottom: 0;
}
.widget-area .widget_search form .screen-reader-text {
  display: none;
}
.widget-area .widget_search form .search-field {
  height: 50px;
  display: block;
  width: 100%;
  border: none;
  border-radius: 5px;
  padding: 2px 0 0 15px;
  color: var(--blackColor);
  background-color: #f2f4f5;
  transition: var(--transition);
  font-size: var(--fontSize);
  font-weight: 500;
}
.widget-area .widget_search form .search-field::placeholder {
  transition: var(--transition);
  color: var(--optionalColor);
}
.widget-area .widget_search form .search-field:focus::placeholder {
  color: transparent;
}
.widget-area .widget_search form button {
  top: 5px;
  right: 5px;
  padding: 0;
  width: 40px;
  border: none;
  height: 40px;
  font-size: 20px;
  position: absolute;
  color: var(--mainColor);
  transition: var(--transition);
  background-color: var(--whiteColor);
}
.widget-area .widget_search form button i {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.widget-area .widget_search form button:hover {
  border-radius: 5px;
  background-color: var(--mainColor);
  color: var(--whiteColor);
}
.widget-area .widget_fiwan_posts_thumb {
  position: relative;
  overflow: hidden;
}
.widget-area .widget_fiwan_posts_thumb .item {
  margin-bottom: 20px;
  position: relative;
  padding-left: 95px;
}
.widget-area .widget_fiwan_posts_thumb .item .thumb {
  top: 0;
  left: 0;
  width: 80px;
  float: unset;
  height: 100%;
  display: block;
  position: absolute;
}
.widget-area .widget_fiwan_posts_thumb .item .thumb .fullimage {
  width: 80px;
  height: 100%;
  display: inline-block;
  background-size: cover !important;
  background-repeat: no-repeat;
  background-position: center center !important;
}
.widget-area .widget_fiwan_posts_thumb .item .thumb .fullimage.bg1 {
  background-image: url(assets/img/blog/blog1.jpg);
}
.widget-area .widget_fiwan_posts_thumb .item .thumb .fullimage.bg2 {
  background-image: url(assets/img/blog/blog2.jpg);
}
.widget-area .widget_fiwan_posts_thumb .item .thumb .fullimage.bg3 {
  background-image: url(assets/img/blog/blog3.jpg);
}
.widget-area .widget_fiwan_posts_thumb .item .thumb .fullimage.bg4 {
  background-image: url(assets/img/blog/blog4.jpg);
}
.widget-area .widget_fiwan_posts_thumb .item .thumb::before, .widget-area .widget_fiwan_posts_thumb .item .thumb::after {
  content: "";
  z-index: 1;
  top: 50%;
  left: 50%;
  opacity: 0;
  position: absolute;
  background-color: var(--whiteColor);
  transform: translate(-50%, -50%);
  transition: all 0.8s cubic-bezier(0.2, 1, 0.22, 1);
}
.widget-area .widget_fiwan_posts_thumb .item .thumb::before {
  width: 40px;
  height: 1px;
  left: 100%;
}
.widget-area .widget_fiwan_posts_thumb .item .thumb::after {
  height: 40px;
  width: 1px;
  top: 0;
}
.widget-area .widget_fiwan_posts_thumb .item .info .title {
  margin-bottom: 8px;
  line-height: 1.4;
  font-size: 16px;
  font-weight: 600;
}
.widget-area .widget_fiwan_posts_thumb .item .info .title a {
  display: inline-block;
}
.widget-area .widget_fiwan_posts_thumb .item .info .meta {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.widget-area .widget_fiwan_posts_thumb .item .info .meta li {
  display: inline-block;
  margin-right: 15px;
  color: var(--optionalColor);
  position: relative;
  padding-left: 20px;
}
.widget-area .widget_fiwan_posts_thumb .item .info .meta li i {
  position: absolute;
  left: 0;
  top: 4px;
}
.widget-area .widget_fiwan_posts_thumb .item .info .meta li a {
  display: inline-block;
  color: var(--optionalColor);
}
.widget-area .widget_fiwan_posts_thumb .item .info .meta li a:hover {
  color: var(--mainColor);
}
.widget-area .widget_fiwan_posts_thumb .item .info .meta li:last-child {
  margin-right: 0;
}
.widget-area .widget_fiwan_posts_thumb .item:last-child {
  margin-bottom: 0;
}
.widget-area .widget_fiwan_posts_thumb .item:hover .thumb::before, .widget-area .widget_fiwan_posts_thumb .item:hover .thumb::after {
  opacity: 1;
  top: 50%;
  left: 50%;
}
.widget-area .widget_socials_link ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.widget-area .widget_socials_link ul li {
  margin-bottom: 15px;
}
.widget-area .widget_socials_link ul li a {
  display: block;
  background-color: #f5f5f5;
  border-radius: 0 0 15px 15px;
  border-bottom: 3px solid #d6d6d6;
  position: relative;
  color: var(--blackColor);
  padding-top: 15px;
  padding-bottom: 12px;
  padding-right: 15px;
  padding-left: 90px;
  font-size: var(--fontSize);
  font-weight: 600;
}
.widget-area .widget_socials_link ul li a i {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 70px;
  background-color: var(--mainColor);
  color: var(--whiteColor);
  border-radius: 0 0 0 15px;
  font-size: 22px;
  text-align: center;
  transition: var(--transition);
}
.widget-area .widget_socials_link ul li a i::before {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.widget-area .widget_socials_link ul li a i.bxl-facebook {
  background-color: #485fb3;
}
.widget-area .widget_socials_link ul li a i.bxl-twitter {
  background-color: #00afe1;
}
.widget-area .widget_socials_link ul li a i.bxl-linkedin {
  background-color: #0e76a8;
}
.widget-area .widget_socials_link ul li a:hover {
  border-color: var(--mainColor);
  border-radius: 0;
}
.widget-area .widget_socials_link ul li a:hover i {
  border-radius: 0;
}
.widget-area .widget_socials_link ul li:last-child {
  margin-bottom: 0;
}
.widget-area .widget_buy_now {
  position: relative;
  text-align: center;
  border-radius: 0 0 15px 15px;
  border-bottom: 3px solid #d6d6d6;
}
.widget-area .widget_buy_now img {
  border-radius: 0 0 15px 15px;
}
.widget-area .widget_buy_now .content {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: var(--whiteColor);
  padding: 30px 25px;
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
}
.widget-area .widget_buy_now .content img {
  border-radius: 0;
}
.widget-area .widget_buy_now .content p {
  line-height: 1.6;
  margin-top: 15px;
  margin-bottom: 15px;
}
.widget-area .widget_buy_now .content .buy-now-btn {
  border: none;
  border-radius: 5px;
  display: inline-block;
  padding: 10px 35px;
  color: var(--whiteColor);
  transition: var(--transition);
  background-color: var(--blackColor);
  font-size: 16px;
  font-weight: 500;
}
.widget-area .widget_buy_now .content .buy-now-btn:hover {
  background-color: var(--mainColor);
  color: var(--whiteColor);
}
.widget-area .widget_buy_now .content::before {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: -10px;
  z-index: -1;
  content: "";
  position: absolute;
  border: 1px solid var(--whiteColor);
}
.widget-area .tagcloud {
  margin-top: -8px;
}
.widget-area .tagcloud a {
  display: inline-block;
  background: #f5f5f5;
  color: var(--optionalColor);
  padding: 7px 15px 6px;
  border: none;
  border-radius: 3px;
  font-weight: 600;
  font-size: 15.5px !important;
  margin-top: 8px;
  margin-right: 4px;
}
.widget-area .tagcloud a:hover {
  color: var(--whiteColor);
  background-color: var(--mainColor);
}
.widget-area .widget_price_filter .collection_filter_by_price .irs-handle {
  cursor: pointer;
}
.widget-area .widget_price_filter .collection_filter_by_price .irs--flat .irs-from, .widget-area .widget_price_filter .collection_filter_by_price .irs--flat .irs-to, .widget-area .widget_price_filter .collection_filter_by_price .irs--flat .irs-single {
  background-color: var(--mainColor);
  padding: 1px 5px 1px 11.5px;
}
.widget-area .widget_price_filter .collection_filter_by_price .irs--flat .irs-from::before, .widget-area .widget_price_filter .collection_filter_by_price .irs--flat .irs-to::before, .widget-area .widget_price_filter .collection_filter_by_price .irs--flat .irs-single::before {
  border-top-color: var(--mainColor);
}
.widget-area .widget_price_filter .collection_filter_by_price .irs--flat .irs-from::after, .widget-area .widget_price_filter .collection_filter_by_price .irs--flat .irs-to::after, .widget-area .widget_price_filter .collection_filter_by_price .irs--flat .irs-single::after {
  content: "$";
  position: absolute;
  left: 5px;
  top: 0.5px;
}
.widget-area .widget_price_filter .collection_filter_by_price .irs--flat .irs-bar {
  background-color: var(--mainColor);
}
.widget-area .widget_price_filter .collection_filter_by_price .irs--flat .irs-handle > i:first-child {
  background-color: var(--mainColor);
}
.widget-area .widget_price_filter .collection_filter_by_price .irs--flat .irs-min, .widget-area .widget_price_filter .collection_filter_by_price .irs--flat .irs-max {
  padding: 1px 3px 1px 9.5px;
}
.widget-area .widget_price_filter .collection_filter_by_price .irs--flat .irs-min::before, .widget-area .widget_price_filter .collection_filter_by_price .irs--flat .irs-max::before {
  content: "$";
  position: absolute;
  left: 3px;
  top: 0.5px;
}
.widget-area .widget_categories ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.widget-area .widget_categories ul li {
  overflow: hidden;
  margin-bottom: 12px;
  padding-bottom: 12px;
  border-bottom: 1px dashed #eeeeee;
  font-size: 15.5px;
  font-weight: 600;
}
.widget-area .widget_categories ul li a {
  display: inline-block;
}
.widget-area .widget_categories ul li .post_count {
  float: right;
}
.widget-area .widget_categories ul li:last-child {
  margin-bottom: 0;
}
.widget-area .widget_top_rated_products .item {
  margin-bottom: 15px;
  position: relative;
  padding-left: 95px;
}
.widget-area .widget_top_rated_products .item .thumb {
  top: 0;
  left: 0;
  width: 80px;
  float: unset;
  height: 100%;
  display: block;
  position: absolute;
}
.widget-area .widget_top_rated_products .item .thumb .fullimage {
  border: 1px solid #eeeeee;
  width: 80px;
  height: 100%;
  display: inline-block;
  background-size: cover !important;
  background-repeat: no-repeat;
  background-position: center center !important;
}
.widget-area .widget_top_rated_products .item .thumb .fullimage.bg1 {
  background-image: url(assets/img/products/products4.jpg);
}
.widget-area .widget_top_rated_products .item .thumb .fullimage.bg2 {
  background-image: url(assets/img/products/products5.jpg);
}
.widget-area .widget_top_rated_products .item .thumb .fullimage.bg3 {
  background-image: url(assets/img/products/products6.jpg);
}
.widget-area .widget_top_rated_products .item .thumb .fullimage.bg4 {
  background-image: url(assets/img/products/products7.jpg);
}
.widget-area .widget_top_rated_products .item .info {
  padding-top: 5px;
  padding-bottom: 5px;
}
.widget-area .widget_top_rated_products .item .info .title {
  margin-bottom: 10px;
  line-height: 1.4;
  font-size: 15.5px;
  font-weight: 600;
}
.widget-area .widget_top_rated_products .item .info .title a {
  display: inline-block;
}
.widget-area .widget_top_rated_products .item .info .star-rating {
  font-size: 15px;
}
.widget-area .widget_top_rated_products .item .info .star-rating i {
  color: #f49f0b;
}
.widget-area .widget_top_rated_products .item .info .price {
  margin-top: 5px;
  font-weight: 600;
  display: block;
  color: var(--optionalColor);
}
.widget-area .widget_top_rated_products .item:last-child {
  margin-bottom: 0;
}
.widget-area .widget_colors ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.widget-area .widget_colors ul li {
  display: block;
  margin-bottom: 12px;
  border-bottom: 1px dashed #eeeeee;
  padding-bottom: 12px;
}
.widget-area .widget_colors ul li a {
  display: flex;
  align-items: center;
}
.widget-area .widget_colors ul li a span {
  display: block;
  font-weight: 600;
  color: var(--optionalColor);
}
.widget-area .widget_colors ul li a span:nth-child(1) {
  width: 17px;
  height: 17px;
  font-size: 0;
  margin-right: 8px;
  letter-spacing: 0;
  border-radius: 50%;
  transition: var(--transition);
}
.widget-area .widget_colors ul li a span:nth-child(1).gray {
  background-color: gray;
}
.widget-area .widget_colors ul li a span:nth-child(1).red {
  background-color: red;
}
.widget-area .widget_colors ul li a span:nth-child(1).green {
  background-color: green;
}
.widget-area .widget_colors ul li a span:nth-child(1).black {
  background-color: black;
}
.widget-area .widget_colors ul li a span:nth-child(1).brown {
  background-color: brown;
}
.widget-area .widget_colors ul li a:hover span:nth-child(1) {
  transform: scale(1.2);
}
.widget-area.left-widget-area {
  padding-left: 0;
  padding-right: 15px;
}

.billing-details .form-group .ngx-dropdown-container .ngx-dropdown-button {
  border: 1px solid #eeeeee !important;
  margin-bottom: 0 !important;
  overflow: unset !important;
  min-height: auto !important;
  border-radius: 5px !important;
  height: 45px !important;
  line-height: 41px !important;
  display: block !important;
  color: var(--blackColor) !important;
  transition: var(--transition) !important;
  background: #f8f8f8 !important;
  font-size: var(--fontSize) !important;
  font-weight: 600 !important;
  padding-left: 12px !important;
  padding-right: 0 !important;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}
.billing-details .form-group .ngx-dropdown-container .ngx-dropdown-button .nsdicon-angle-down {
  right: 12px !important;
}
.billing-details .form-group .ngx-dropdown-container .ngx-dropdown-button .nsdicon-angle-down::before {
  height: 8px !important;
  width: 8px !important;
  top: 3px !important;
  border-color: var(--mainColor) !important;
}
.billing-details .form-group .ngx-dropdown-container .ngx-dropdown-button:hover {
  border-color: var(--mainColor) !important;
}
.billing-details .form-group .ngx-dropdown-container .ngx-dropdown-list-container {
  border: none !important;
  width: 100% !important;
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  border-radius: 0 !important;
  padding-top: 0 !important;
  padding-left: 15px !important;
  padding-bottom: 15px !important;
  background-color: var(--whiteColor) !important;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2) !important;
}
.billing-details .form-group .ngx-dropdown-container .ngx-dropdown-list-container .search-container input {
  border-color: #eeeeee !important;
}
.billing-details .form-group .ngx-dropdown-container .ngx-dropdown-list-container ul {
  margin-top: 15px !important;
  margin-bottom: 5px !important;
  padding-left: 0 !important;
  text-align: left !important;
}
.billing-details .form-group .ngx-dropdown-container .ngx-dropdown-list-container ul li {
  position: relative !important;
  color: var(--blackColor) !important;
  transition: var(--transition) !important;
  text-align: left;
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-top: 15px !important;
  padding-bottom: 0 !important;
  font-size: var(--fontSize) !important;
  font-weight: 500 !important;
}
.billing-details .form-group .ngx-dropdown-container .ngx-dropdown-list-container ul li:first-child {
  padding-top: 0 !important;
}
.billing-details .form-group .ngx-dropdown-container .ngx-dropdown-list-container ul li:hover {
  color: var(--mainColor) !important;
}
.billing-details .form-group .ngx-dropdown-container .ngx-dropdown-list-container ul.selected-items li {
  background-color: transparent !important;
  color: var(--mainColor) !important;
  margin-bottom: 2px !important;
}

.fiwan-grid-sorting .ordering .ngx-dropdown-container {
  width: 215px !important;
  display: inline-block !important;
}
.fiwan-grid-sorting .ordering .ngx-dropdown-container .ngx-dropdown-button {
  border: 1px solid #eeeeee !important;
  margin-bottom: 0 !important;
  overflow: unset !important;
  min-height: auto !important;
  border-radius: 5px !important;
  height: 45px !important;
  line-height: 35px !important;
  display: block !important;
  color: var(--blackColor) !important;
  transition: var(--transition) !important;
  background: #f8f8f8 !important;
  font-size: var(--fontSize) !important;
  font-weight: 600 !important;
  padding-left: 12px !important;
  padding-right: 0 !important;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}
.fiwan-grid-sorting .ordering .ngx-dropdown-container .ngx-dropdown-button .nsdicon-angle-down {
  right: 12px !important;
}
.fiwan-grid-sorting .ordering .ngx-dropdown-container .ngx-dropdown-button .nsdicon-angle-down::before {
  height: 8px !important;
  width: 8px !important;
  top: 3px !important;
  border-color: var(--mainColor) !important;
}
.fiwan-grid-sorting .ordering .ngx-dropdown-container .ngx-dropdown-button:hover {
  border-color: var(--mainColor) !important;
}
.fiwan-grid-sorting .ordering .ngx-dropdown-container .ngx-dropdown-list-container {
  border: none !important;
  width: 100% !important;
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  border-radius: 0 !important;
  padding-top: 0 !important;
  padding-left: 15px !important;
  padding-bottom: 15px !important;
  background-color: var(--whiteColor) !important;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2) !important;
}
.fiwan-grid-sorting .ordering .ngx-dropdown-container .ngx-dropdown-list-container .search-container input {
  border-color: #eeeeee !important;
}
.fiwan-grid-sorting .ordering .ngx-dropdown-container .ngx-dropdown-list-container ul {
  margin-top: 15px !important;
  margin-bottom: 5px !important;
  padding-left: 0 !important;
  text-align: left !important;
}
.fiwan-grid-sorting .ordering .ngx-dropdown-container .ngx-dropdown-list-container ul li {
  position: relative !important;
  color: var(--blackColor) !important;
  transition: var(--transition) !important;
  text-align: left;
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-top: 15px !important;
  padding-bottom: 0 !important;
  font-size: var(--fontSize) !important;
  font-weight: 500 !important;
}
.fiwan-grid-sorting .ordering .ngx-dropdown-container .ngx-dropdown-list-container ul li:first-child {
  padding-top: 0 !important;
}
.fiwan-grid-sorting .ordering .ngx-dropdown-container .ngx-dropdown-list-container ul li:hover {
  color: var(--mainColor) !important;
}
.fiwan-grid-sorting .ordering .ngx-dropdown-container .ngx-dropdown-list-container ul.selected-items li {
  background-color: transparent !important;
  color: var(--mainColor) !important;
  margin-bottom: 2px !important;
}

@keyframes movebounce {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
}
/*================================================
Modal CSS
=================================================*/
.modal.right .modal-dialog {
  position: fixed;
  margin: auto;
  width: 420px;
  height: 100%;
  transform: translate3d(0%, 0, 0);
}
.modal.right .modal-content {
  height: 100%;
  overflow-y: auto;
  background-color: var(--whiteColor);
  border: none;
  border-radius: 0;
}
.modal.right .modal-content button.close {
  float: unset;
  position: absolute;
  right: 20px;
  top: 15px;
  color: var(--blackColor);
  z-index: 2;
  background-color: transparent;
  opacity: 1;
  border: none;
  text-shadow: unset;
  box-shadow: unset;
  font-size: 40px;
  padding: 0;
  transition: var(--transition);
}
.modal.right .modal-content button.close:hover {
  color: red;
}
.modal.right.fade .modal-dialog {
  right: -320px;
  transition: opacity 0.3s linear, right 0.3s ease-out;
}
.modal.right.fade.show .modal-dialog {
  right: 0;
}

.sidebarModal.modal .modal-body {
  text-align: center;
  padding: 80px 30px;
}
.sidebarModal.modal .modal-body .instagram-list {
  margin-top: 60px;
}
.sidebarModal.modal .modal-body .instagram-list .row {
  margin-left: -5px;
  margin-right: -5px;
}
.sidebarModal.modal .modal-body .instagram-list .row .col-lg-4 {
  padding-left: 5px;
  padding-right: 5px;
}
.sidebarModal.modal .modal-body .instagram-list .box {
  position: relative;
  z-index: 1;
  overflow: hidden;
  margin-top: 10px;
}
.sidebarModal.modal .modal-body .instagram-list .box .link-btn {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 3;
}
.sidebarModal.modal .modal-body .instagram-list .box i {
  position: absolute;
  left: 0;
  right: 0;
  color: var(--whiteColor);
  top: 50%;
  transform: translateY(-50%);
  font-size: 30px;
  transition: var(--transition);
  opacity: 0;
  visibility: hidden;
  z-index: 2;
}
.sidebarModal.modal .modal-body .instagram-list .box img {
  transition: var(--transition);
}
.sidebarModal.modal .modal-body .instagram-list .box::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  background-color: var(--mainColor);
  transition: var(--transition);
  opacity: 0;
  visibility: hidden;
}
.sidebarModal.modal .modal-body .instagram-list .box:hover::before {
  opacity: 0.8;
  visibility: visible;
}
.sidebarModal.modal .modal-body .instagram-list .box:hover img {
  transform: scale(1.3);
}
.sidebarModal.modal .modal-body .instagram-list .box:hover i {
  opacity: 1;
  visibility: visible;
}
.sidebarModal.modal .modal-body .sidebar-contact-info {
  margin-top: 60px;
}
.sidebarModal.modal .modal-body .sidebar-contact-info h2 {
  margin-bottom: 0;
  font-size: 30px;
  font-weight: 600;
}
.sidebarModal.modal .modal-body .sidebar-contact-info h2 span {
  display: block;
  font-size: var(--fontSize);
  margin-top: 10px;
  margin-bottom: 8px;
}
.sidebarModal.modal .modal-body .social-list {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
  margin-top: 15px;
}
.sidebarModal.modal .modal-body .social-list li {
  display: inline-block;
  margin-right: 8px;
}
.sidebarModal.modal .modal-body .social-list li span {
  display: block;
  color: var(--mainColor);
  font-weight: 600;
}
.sidebarModal.modal .modal-body .social-list li a {
  position: relative;
  top: 3px;
  font-size: 20px;
}
.sidebarModal.modal .modal-body .social-list li:last-child {
  margin-right: 0;
}

.productsFilterModal.modal .modal-body {
  padding: 0;
}
.productsFilterModal.modal .modal-content {
  padding: 30px;
}
.productsFilterModal.modal .modal-content button.close {
  top: 0;
  right: 0;
  font-size: 17px;
  margin-bottom: 35px;
  text-align: left;
  position: relative;
  padding: 0;
  padding-left: 25px;
  background-color: transparent;
  border: none;
}
.productsFilterModal.modal .modal-content button.close i {
  position: absolute;
  font-size: 20px;
  left: 0;
  top: 0;
}

.productsQuickView {
  padding-right: 0 !important;
}
.productsQuickView .modal-dialog {
  max-width: 900px;
  margin: 0 auto;
}
.productsQuickView .modal-content {
  border: none;
  padding: 40px;
  background-color: #f9f9f9;
  border-radius: 0;
}
.productsQuickView .modal-content button.close {
  top: 5px;
  right: 5px;
  z-index: 1;
  outline: 0;
  padding: 0;
  margin: 0;
  opacity: 1;
  width: 35px;
  height: 35px;
  font-size: 25px;
  line-height: 41px;
  position: absolute;
  text-shadow: unset;
  border-radius: 50%;
  color: var(--blackColor);
  transition: var(--transition);
  border: none;
  padding: 0;
  background-color: var(--whiteColor);
}
.productsQuickView .modal-content button.close:hover {
  background-color: red;
  color: var(--whiteColor);
}
.productsQuickView .modal-content .products-content h3 {
  margin-bottom: 0;
  font-size: 22px;
}
.productsQuickView .modal-content .products-content .price {
  transition: var(--transition);
  margin-top: 12px;
  margin-bottom: 15px;
  font-size: var(--fontSize);
  font-weight: 600;
}
.productsQuickView .modal-content .products-content .price .old-price {
  text-decoration: line-through;
  color: #999999;
  font-weight: 500;
}
.productsQuickView .modal-content .products-content .products-review {
  margin-bottom: 15px;
}
.productsQuickView .modal-content .products-content .products-review .rating {
  display: inline-block;
  padding-right: 5px;
}
.productsQuickView .modal-content .products-content .products-review .rating i {
  color: #f49f0b;
  display: inline-block;
}
.productsQuickView .modal-content .products-content .products-review .rating-count {
  display: inline-block;
  color: var(--blackColor);
  border-bottom: 1px solid var(--blackColor);
  line-height: initial;
  position: relative;
  top: -1px;
}
.productsQuickView .modal-content .products-content .products-review .rating-count:hover {
  color: var(--mainColor);
  border-color: var(--mainColor);
}
.productsQuickView .modal-content .products-content .products-info {
  list-style-type: none;
  padding-left: 0;
  margin-top: 15px;
  margin-bottom: 0;
}
.productsQuickView .modal-content .products-content .products-info li {
  color: var(--optionalColor);
  margin-bottom: 8px;
}
.productsQuickView .modal-content .products-content .products-info li:last-child {
  margin-bottom: 0;
}
.productsQuickView .modal-content .products-content .products-info li span {
  color: var(--optionalColor);
}
.productsQuickView .modal-content .products-content .products-info li a {
  display: inline-block;
  font-weight: 600;
  color: var(--blackColor);
}
.productsQuickView .modal-content .products-content .products-info li a:hover {
  color: var(--mainColor);
}
.productsQuickView .modal-content .products-content .products-add-to-cart {
  margin-top: 15px;
}
.productsQuickView .modal-content .products-content .products-add-to-cart .input-counter {
  max-width: 110px;
  min-width: 110px;
  margin-right: 10px;
  text-align: center;
  display: inline-block;
  position: relative;
}
.productsQuickView .modal-content .products-content .products-add-to-cart .input-counter span {
  top: 0;
  cursor: pointer;
  color: #d0d0d0;
  width: 40px;
  height: 100%;
  line-height: 55px;
  font-size: 18px;
  position: absolute;
  background-color: transparent;
  transition: var(--transition);
}
.productsQuickView .modal-content .products-content .products-add-to-cart .input-counter span.minus-btn {
  left: 0;
}
.productsQuickView .modal-content .products-content .products-add-to-cart .input-counter span.plus-btn {
  right: 0;
}
.productsQuickView .modal-content .products-content .products-add-to-cart .input-counter span:hover {
  color: var(--mainColor);
}
.productsQuickView .modal-content .products-content .products-add-to-cart .input-counter input {
  height: 50px;
  outline: 0;
  display: block;
  border: none;
  text-align: center;
  width: 100%;
  color: var(--blackColor);
  background-color: var(--whiteColor);
  font-size: 17px;
  font-weight: 600;
}
.productsQuickView .modal-content .products-content .products-add-to-cart .input-counter input::placeholder {
  color: var(--blackColor);
}
.productsQuickView .modal-content .products-content .products-add-to-cart .input-counter .default-btn i {
  margin-top: 0;
}

/* Max width 767px */
@media only screen and (max-width: 767px) {
  body {
    font-size: 14px;
  }
  p {
    font-size: 14px;
  }
  .ptb-100 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .pt-100 {
    padding-top: 60px;
  }
  .pb-100 {
    padding-bottom: 60px;
  }
  .ptb-70 {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .pt-70 {
    padding-top: 30px;
  }
  .pb-70 {
    padding-bottom: 30px;
  }
  .container {
    max-width: 100%;
  }
  .section-title {
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
    margin-top: -7px;
    margin-bottom: 40px;
  }
  .section-title h2 {
    font-size: 24px;
  }
  .default-btn {
    font-size: 14px;
    padding: 11px 20px 10px 45px;
  }
  .default-btn i {
    left: 20px;
    font-size: 15px;
  }
  .form-control {
    font-size: 14px;
    padding-left: 10px;
  }
  .fiwan-responsive-nav .fiwan-responsive-menu.mean-container .mean-nav {
    margin-top: 39px;
  }
  .fiwan-responsive-nav .fiwan-responsive-menu.mean-container .mean-nav ul {
    font-size: 15px;
  }
  .fiwan-responsive-nav .fiwan-responsive-menu.mean-container .mean-nav ul li a.active {
    color: var(--mainColor);
  }
  .fiwan-responsive-nav .fiwan-responsive-menu.mean-container .mean-nav ul li li a {
    font-size: 15px;
  }
  .fiwan-responsive-nav .fiwan-responsive-menu.mean-container .navbar-nav {
    overflow-y: scroll;
    height: 357px;
    box-shadow: 0 7px 13px 0 rgba(0, 0, 0, 0.1);
  }
  .fiwan-responsive-nav .mean-container a.meanmenu-reveal {
    color: var(--blackColor);
  }
  .fiwan-responsive-nav .mean-container a.meanmenu-reveal span {
    background: var(--blackColor);
  }
  .home-slides .owl-theme .owl-nav {
    left: 0;
    bottom: 0;
    bottom: 0;
    margin-top: 20px;
    position: relative;
  }
  .home-slides .owl-theme .owl-nav [class*=owl-] {
    width: 38px;
    height: 38px;
    font-size: 20px;
  }
  .home-slides-two .owl-theme .owl-nav {
    margin-bottom: 60px;
  }
  .home-slides-two .owl-theme .owl-nav [class*=owl-] {
    width: 38px;
    height: 38px;
    font-size: 20px;
  }
  .testimonials-slides .owl-theme .owl-nav [class*=owl-] {
    top: auto;
    bottom: 8px;
    transform: unset;
    width: 35px;
    height: 35px;
    font-size: 20px;
    left: 0;
  }
  .testimonials-slides .owl-theme .owl-nav [class*=owl-].owl-next {
    left: auto;
    right: 0;
  }
  .products-slides .owl-theme .owl-nav.disabled + .owl-dots {
    margin-top: 0;
  }
  .products-slides .owl-theme .owl-dots .owl-dot span {
    width: 15px;
    height: 15px;
    margin: 0 3px;
  }
  .products-details-image-slides .owl-theme .owl-nav {
    opacity: 1;
    visibility: visible;
  }
  .products-details-image-slides .owl-theme .owl-nav [class*=owl-] {
    left: 10px;
    width: 35px;
    height: 35px;
    font-size: 20px;
  }
  .products-details-image-slides .owl-theme .owl-nav [class*=owl-].owl-next {
    left: auto;
    right: 10px;
  }
  .page-title-area {
    margin-top: 55px;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .page-title-area h1 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  .page-title-area ul li::before {
    top: 1.4px;
  }
  .billing-details .form-group .ngx-dropdown-container .ngx-dropdown-button {
    font-size: 14px !important;
  }
  .fiwan-grid-sorting .ordering .ngx-dropdown-container .ngx-dropdown-button {
    font-size: 14px !important;
  }
  .pagination-area {
    margin-top: 5px;
  }
  .pagination-area .ngx-pagination li {
    font-size: 14px;
    margin-left: 3px;
    margin-right: 3px;
  }
  .widget-area {
    padding-left: 0;
    margin-top: 40px;
  }
  .widget-area .widget {
    margin-bottom: 30px;
  }
  .widget-area .widget .widget-title {
    font-size: 17px;
    margin-bottom: 25px;
  }
  .widget-area .widget_fiwan_posts_thumb .item .info .title {
    font-size: 15px;
  }
  .widget-area .widget_fiwan_posts_thumb .item .info .meta li {
    margin-right: 10px;
  }
  .widget-area .widget_socials_link ul li a {
    font-size: 14px;
  }
  .widget-area .widget_buy_now .content {
    padding: 15px;
    max-width: 225px;
  }
  .widget-area .widget_buy_now .content::before {
    margin: -10px;
  }
  .widget-area .widget_categories ul li {
    font-size: 14.5px;
  }
  .widget-area .tagcloud a {
    font-size: 13.5px !important;
  }
  .widget-area.left-widget-area {
    padding-right: 0;
    margin-top: 0;
    margin-bottom: 40px;
  }
  .widget-area .widget_top_rated_products .item .info .title {
    font-size: 14.5px;
  }
  .modal.right .modal-dialog {
    width: 100%;
  }
  .modal.right .modal-content button.close {
    right: 20px;
    top: 20px;
    font-size: 25px;
  }
  .sidebarModal.modal .modal-body {
    text-align: left;
    padding: 20px;
  }
  .sidebarModal.modal .modal-body .instagram-list {
    margin-top: 30px;
  }
  .sidebarModal.modal .modal-body .sidebar-contact-info {
    margin-top: 30px;
  }
  .sidebarModal.modal .modal-body .sidebar-contact-info h2 {
    font-size: 20px;
  }
  .sidebarModal.modal .modal-body .sidebar-contact-info h2 span {
    font-size: 13px;
  }
  .sidebarModal.modal .modal-body .social-list li a {
    position: relative;
    top: 3px;
    font-size: 17px;
  }
  .productsQuickView .modal-dialog {
    max-width: 300px;
    margin: 0 auto;
  }
  .productsQuickView .modal-content {
    padding: 15px;
  }
  .productsQuickView .modal-content .products-content {
    margin-top: 25px;
  }
  .productsQuickView .modal-content .products-content h3 {
    font-size: 18px;
  }
  .productsQuickView .modal-content .products-content .price {
    font-size: 14px;
  }
  .productsQuickView .modal-content .products-content .products-info li {
    font-size: 14px;
  }
  .productsQuickView .modal-content .products-content .products-color-switch h4 {
    font-size: 14px;
  }
  .productsQuickView .modal-content .products-content .products-size-wrapper h4 {
    font-size: 14px;
  }
  .productsQuickView .modal-content .products-content .products-add-to-cart .input-counter input {
    height: 43px;
    font-size: 16px;
  }
  .productsQuickView .modal-content .products-content .products-add-to-cart .input-counter span {
    line-height: 49px;
  }
  .productsFilterModal.modal .modal-content {
    padding: 20px;
  }
  .productsFilterModal.modal .modal-content button.close {
    margin-bottom: 25px;
    right: 0;
    top: 0;
    font-size: 17px;
  }
  .productsFilterModal.modal .modal-content .widget-area {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}
/* Min width 576px to Max width 767px */
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .container {
    max-width: 540px;
  }
  .productsQuickView .modal-dialog {
    max-width: 500px;
  }
  .productsQuickView .modal-content {
    padding: 30px;
  }
}
/* Min width 768px to Max width 991px */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  body {
    font-size: 15px;
  }
  p {
    font-size: 15px;
  }
  .ptb-100 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .pt-100 {
    padding-top: 80px;
  }
  .pb-100 {
    padding-bottom: 80px;
  }
  .ptb-70 {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .pt-70 {
    padding-top: 50px;
  }
  .pb-70 {
    padding-bottom: 50px;
  }
  .container {
    max-width: 720px;
  }
  .section-title {
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 50px;
  }
  .section-title h2 {
    margin-top: 20px;
    font-size: 30px;
  }
  .section-title p {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
  .default-btn {
    font-size: 15px;
    padding: 11px 25px 11px 50px;
  }
  .default-btn i {
    left: 22px;
    font-size: 18px;
    margin-top: -1px;
  }
  .fiwan-responsive-nav .fiwan-responsive-menu.mean-container .mean-nav {
    margin-top: 39px;
  }
  .fiwan-responsive-nav .fiwan-responsive-menu.mean-container .mean-nav ul {
    font-size: 15px;
  }
  .fiwan-responsive-nav .fiwan-responsive-menu.mean-container .mean-nav ul li a.active {
    color: var(--mainColor);
  }
  .fiwan-responsive-nav .fiwan-responsive-menu.mean-container .mean-nav ul li li a {
    font-size: 15px;
  }
  .fiwan-responsive-nav .fiwan-responsive-menu.mean-container .navbar-nav {
    overflow-y: scroll;
    height: 357px;
    box-shadow: 0 7px 13px 0 rgba(0, 0, 0, 0.1);
  }
  .fiwan-responsive-nav .mean-container a.meanmenu-reveal {
    color: var(--blackColor);
  }
  .fiwan-responsive-nav .mean-container a.meanmenu-reveal span {
    background: var(--blackColor);
  }
  .home-slides .owl-theme .owl-nav {
    left: 0;
    position: relative;
    margin-top: 30px;
  }
  .home-slides-two .owl-theme .owl-nav {
    margin-bottom: 80px;
  }
  .products-slides .owl-theme .owl-nav.disabled + .owl-dots {
    margin-top: 0;
  }
  .testimonials-slides .owl-theme .owl-nav [class*=owl-] {
    top: auto;
    bottom: 9px;
    transform: unset;
    left: 0;
  }
  .testimonials-slides .owl-theme .owl-nav [class*=owl-].owl-next {
    left: auto;
    right: 0;
  }
  .products-details-image-slides .owl-theme .owl-nav {
    opacity: 1;
    visibility: visible;
  }
  .page-title-area {
    margin-top: 55px;
  }
  .page-title-area h1 {
    font-size: 30px;
  }
  .widget-area {
    padding-left: 0;
    margin-top: 40px;
  }
  .widget-area .widget {
    margin-bottom: 30px;
  }
  .widget-area .widget .widget-title {
    font-size: 19px;
    margin-bottom: 25px;
  }
  .widget-area.left-widget-area {
    padding-right: 0;
    margin-top: 0;
    margin-bottom: 40px;
  }
  .productsQuickView .modal-dialog {
    max-width: 740px;
  }
  .productsQuickView .modal-content {
    padding: 20px;
  }
  .productsFilterModal.modal .modal-content .widget-area {
    margin-bottom: 0;
  }
}
/* Min width 992px to Max width 1199px */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-title h2 {
    font-size: 32px;
  }
  .container {
    max-width: 960px;
  }
  .default-btn {
    font-size: 15px;
  }
  .fiwan-responsive-nav .fiwan-responsive-menu.mean-container .mean-nav {
    margin-top: 46px;
  }
  .fiwan-responsive-nav .fiwan-responsive-menu.mean-container .mean-nav ul {
    font-size: 15px;
  }
  .fiwan-responsive-nav .fiwan-responsive-menu.mean-container .mean-nav ul li a.active {
    color: var(--mainColor);
  }
  .fiwan-responsive-nav .fiwan-responsive-menu.mean-container .mean-nav ul li li a {
    font-size: 15px;
  }
  .fiwan-responsive-nav .fiwan-responsive-menu.mean-container .navbar-nav {
    overflow-y: scroll;
    height: 357px;
    box-shadow: 0 7px 13px 0 rgba(0, 0, 0, 0.1);
  }
  .fiwan-responsive-nav .mean-container a.meanmenu-reveal {
    color: var(--blackColor);
  }
  .fiwan-responsive-nav .mean-container a.meanmenu-reveal span {
    background: var(--blackColor);
  }
  .home-slides .owl-theme .owl-nav {
    left: 0;
  }
  .widget-area {
    padding-left: 0;
  }
  .widget-area .widget_buy_now .content {
    max-width: 220px;
  }
  .widget-area.left-widget-area {
    padding-right: 0;
  }
  .page-title-area {
    margin-top: 65px;
  }
}
/* Min width 1200px to Max width 1355px */
@media only screen and (min-width: 1200px) and (max-width: 1355px) {
  .container {
    max-width: 1140px;
  }
  .home-slides .owl-theme .owl-nav {
    left: 50px;
  }
}
/* Min width 1550px */
@media only screen and (min-width: 1550px) {
  .home-slides .owl-theme .owl-nav {
    left: 100px;
  }
}
.partner-slides .owl-carousel .owl-item {
  width: 130px !important;
}